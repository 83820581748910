import Response from './index'

export default class ResponseSuccess<T> extends Response<T> {
  constructor (data?: T) {
    super({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: data || null,
      error: false,
      message: '',
      status: 200
    })
  }
}
