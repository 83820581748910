
// lib
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// app
import config from '@/config'
import UiFormGroup from '@/app/shared/components/ui-form-group.vue'
import Product from '@/app/shared/models/Product'
import ProductService from '@/app/shared/services/ProductService'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'

@Component({
  components: { UiFormGroup }
})
export default class AppArticleProduct extends Vue {
  @Prop({ type: ConstructorComponent }) readonly value!: ConstructorComponent

  config = config
  product: Product | null = null
  search = ''

  get idProp (): number {
    return this.value.attrs.product as number | undefined ?? 0
  }

  async handleSearchProduct (): Promise<void> {
    this.product = await ProductService.fetchByCode(this.search)

    this.emitResult()
  }

  onCLick(): void {
    this.product = null

    this.emitResult()
  }

  @Watch('idProp', { immediate: true })
  async initialProducts (value: number): Promise<void> {
    if (!value) {
      return
    }

    const data = await ProductService.fetchById(value)

    this.product = data as Product
  }

  emitResult (): void {
    if (this.product) {
      this.$emit('input', new ConstructorComponent({
        ...this.value,
        $uuid: this.value.$uuid,
        attrs: {
          product: this.product.id
        }
      }))
    }
  }
}
