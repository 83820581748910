// lib
import axios, { AxiosResponse } from 'axios'

// app
import config from '@/config'
import Response from '@/app/shared/models/response'
import Product, { IProduct } from '@/app/shared/models/Product'
import ResponseSuccess from '@/app/shared/models/response/ResponseSuccess'

export default class ProductService {
  static async fetchById (id: number): Promise<IProduct> {
    const response: AxiosResponse<Response<IProduct>> = await axios.get(`/product/${id}/1`, {
      baseURL: config.http.siteUrlApi
    })

    return response.data.data
  }

  static async fetchByCode (code: string): Promise<IProduct> {
    const response: AxiosResponse<Response<IProduct>> = await axios.get(`/product/article/${code}/1`, {
      baseURL: config.http.siteUrlApi
    })

    return response.data.data
  }

  static fetchListByCode (length: number): Promise<Response<Product[]>> {
    const products = []

    for (let i = 0; i < length; i++) {
      products[i] = new Product({
        id: 1,
        code: 'e-100-' + i,
        name: 'Вилочный погрузчик дизельный GOODSENSE FD30T1-X12',
        images: [{ alt: '', image: 'https://diam-almaz.ru/img/files/ostalnoe/pogruzchiki/xgoodsense-fd30g-small.jpg.pagespeed.ic.T_ESr2-w8M.webp' }]
      })
    }

    return Promise.resolve(new ResponseSuccess(products))
  }

  static searchByCode (code: string): Promise<Response<Product[]>> {
    return Promise.resolve(new ResponseSuccess([
      new Product({
        id: 1,
        code,
        name: 'Вилочный погрузчик дизельный GOODSENSE FD30T1-X12',
        images: [{ alt: '', image: 'https://diam-almaz.ru/img/files/ostalnoe/pogruzchiki/xgoodsense-fd30g-small.jpg.pagespeed.ic.T_ESr2-w8M.webp' }]
      })
    ]))
  }
}
