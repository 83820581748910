import Model from '@/app/shared/models/Model'

export interface IProduct {
  id: number
  code: string
  name: string
  gallery?: { alt: string, src: string, type: string }[]
  images?: { alt: string, image: string }[]
}

export default class Product extends Model<IProduct> implements IProduct {
  id = 0
  code = ''
  name = ''
  images?: { alt: string, image: string }[] = []
  gallery?: { alt: string, src: string, type: string }[] = []

  constructor (props?: IProduct) {
    super(props)

    if (props) {
      Object.assign(this, props)
    }
  }
}
