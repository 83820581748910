export default class Response<T, P = undefined> {
  data: T
  pagination?: P
  error: boolean
  message: string
  status: number

  constructor (data: Response<T, P>) {
    this.data = data.data
    this.error = data.error
    this.message = data.message
    this.status = data.status

    if (data.pagination) {
      this.pagination = data.pagination
    }
  }
}
